<template>
  <div class="empty-page">
    <div class="page-image">
      <span class="image-back" />
      <img :src="page.image">
    </div>
    <div class="text">
      <div class="title">{{ page.title }}</div>
      <template v-if="($store.getters.isVolunteer || $store.getters.isLeader)">
        <div class="sub-title">{{ page.subTitle }}</div>
        <div class="bg-image">
          <img src="/images/dashed-arrow.svg">
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyPage',
  props: {
    page: {
      type: Object,
      required: true
    }
  }
};
</script>
