<template>
  <div class="history">
    <template v-if="runs.length">
      <div class="title-wrap">
        <h3 class="page-title">
          {{ $t("history.record") }}
        </h3>
        <social-sharings
          ref="socialSharing"
          url="https://breadline.hkfoodworks.com"
          quote="Hong Kong’s first crowdsourced food rescue platform  - giving old bread new life! So surplus food can be delivered to those who need it."
          hashtags="breadline"
          inline-template>
          <span></span>
        </social-sharings>

        <sharing @share="network => $refs.socialSharing.share(network)" color="#FBE8DC"></sharing>
        <div class="sub-title">
          {{ $t("history.since") }} {{ $d($moment(currentUser.createdAt), 'moye') }}
        </div>
      </div>
      <div class="total-layout">
        <div class="total-wrap">
          <div class="img-wrap">
            <img src="/images/save-bread.svg">
          </div>
          <div class="content">
            <div class="total-title">{{ $t("history.total") }}</div>
            <div class="count">{{ $store.state.auth.stats.totalSavedAmount }}</div>
          </div>
        </div>
        <div class="total-wrap">
          <div class="img-wrap">
            <img src="/images/runs.svg">
          </div>
          <div class="content">
            <div class="total-title">{{ $t("history.totalruns") }}</div>
            <div class="count">{{ $store.state.auth.stats.totalRunsCompleted }}</div>
          </div>
        </div>
      </div>

      <div class="title-wrap mb-5">
        <h3 class="page-title mb-0">
          {{ $t("history.runs") }}
        </h3>
      </div>
      <v-flex md12 v-for="(group, index) in groupedRuns" :key="index">
        <div class="list-head">
          <div class="date">
            {{ $d($moment(group.date), 'long') }}
          </div>
          <dropoff-point :dropoff="group.runs[0].dropoff"></dropoff-point>
        </div>
        <div class="history-card-wrap">
          <template v-for="run in group.runs">
            <run-card :key="run.id" :run="run" :light="true" class="mb-10px" />
          </template>
        </div>
      </v-flex>
    </template>

    <empty-page v-else :page="page" />
  </div>
</template>

<script>
// @ is an alias to /src
import * as userService from '@/services/user';
import EmptyPage from '../../components/empty-page';
import RunCard from '@/components/run-card';
import DropoffPoint from '@/components/dropoff-point';
import Sharing from '@/components/sharing';

export default {
  name: 'home',
  metaInfo: {
    title: 'History'
  },
  components: { RunCard, EmptyPage, DropoffPoint, Sharing },
  data: () => ({
    page: {
      image: '/images/empty-history.jpg',
      title: 'No runs completed... yet!',
      subTitle: 'Tap here to start planning your first run'
    },
    runs: [],
    filters: {
      status: 'completed'
    }
  }),
  computed: {
    groupedRuns () {
      // this gives an object with dates as keys
      const groups = this.runs.reduce((groups, run) => {
        const date = run.date;
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(run);
        return groups;
      }, {});

      const groupsArray = Object.keys(groups).map((date) => {
        return {
          date,
          runs: groups[date]
        };
      });

      return groupsArray.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
    }
  },
  created () {
    this.getRuns();
  },
  methods: {
    getRuns () {
      userService.runs(this.filters)
        .then(response => {
          this.runs = response;
        });
    }
  }
};
</script>
