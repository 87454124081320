import { http } from '@/plugins/http/index';
import { getData } from '@/utils/get';

/**
 * Selection of Dropoff Point by volunteer and date
 *
 * @param filters (date, minpickup, maxpickup)
 * @returns {data: dropoff}
 */
export const get = (params) => http.get('dropselection', { params }).then(getData);

export const select = (params) => http.put('dropselection', params).then(getData);
