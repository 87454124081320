<template>
  <v-dialog
    v-model="dialog"
    max-width="'auto'"
    content-class="drop-off-dialog"
    hide-details
    @click:outside="onClose"
  >
    <template v-slot:activator="{ on }">
      <v-btn text class="food-link" v-on="on" rounded>
        <span class="label"> {{ $t("dropoff.dropoff") }} </span>
        <div class="food-name">{{ dropOffName }}</div>
        <img src="/images/map-marker-red.svg">
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $t("dropoff.point") }}
      </v-card-title>

      <v-card-text v-if="!edit">
        <img src="/images/ngo.svg">
        <h3 class="title">{{ dropOffName }}</h3>
        <address>
          {{ dropOffAddress }}
        </address>
      </v-card-text>
      <v-card-text v-if="edit">
        <img src="/images/ngo.svg">
        <drop-select-new
          :items="dropoffList"
          :selected-item="dropoff.id"
          @input="changeDropoff($event)"
          :prefix="$t('dropoff.dropoff')"
        />
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="!edit"
          class="modal-btn ok"
          text
          target="_blank"
          :href="dropOffMapUrl"
        >
          {{ $t("dropoff.map") }}
        </v-btn>
        <v-btn
          v-if="canBeChanged"
          @click="changeToDropoffSelect"
          class="modal-btn ok"
          text
        >
          {{ $t("dropoff.change") }}
        </v-btn>
        <v-btn
          v-if="edit"
          @click="confirmDropoff"
          class="modal-btn ok"
          text
          :disabled="!dropoffIsChanged"
        >
          {{ $t("dropoff.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DropSelectNew from '@/components/drop-select.vue';
import * as dropSelectionService from '@/services/dropselection';
export default {
  name: 'dropoff-point',
  components: {
    DropSelectNew
  },
  data () {
    return {
      dialog: false,
      edit: false,
      selectedDropoff: null
    };
  },
  props: ['dropoff', 'dropoffList', 'date'],
  created () {
    this.selectedDropoff = this.dropoff;
  },
  computed: {
    dropoffIsChanged () {
      return this.selectedDropoff.id !== this.dropoff.id;
    },
    canBeChanged () {
      return !this.edit && !this.onlyOneDropoff;
    },
    onlyOneDropoff () {
      return this.dropoffList?.length <= 1;
    },
    dropOffName () {
      if (this.$i18n.locale === 'en') {
        return this.dropoff?.nameEn;
      } else {
        return this.dropoff?.nameZh;
      }
    },
    dropOffAddress () {
      if (this.$i18n.locale === 'en') {
        return this.dropoff?.addressFullEn;
      } else {
        return this.dropoff?.addressFullZh;
      }
    },
    dropOffMapUrl () {
      return this.dropoff?.googleMapsUrl;
    },
    confirmDropoffPayload () {
      return {
        date: this.date,
        dropoff_id: this.selectedDropoff.id
      };
    }
  },
  methods: {
    changeToDropoffSelect () {
      this.edit = true;
    },
    changeDropoff (dropId) {
      this.selectedDropoff = this.dropoffList.find(drop => drop.id === dropId);
    },
    async confirmDropoff () {
      this.edit = false;
      this.dialog = false;
      const response = await dropSelectionService.select(this.confirmDropoffPayload);
      this.selectedDrop = response[0];
      await this.$store.dispatch('loadRuns', {});
      this.$emit('input', this.selectedDrop);
    },
    onClose () {
      this.edit = false;
      this.selectedDropoff = this.dropoff;
    }
  }
};
</script>
